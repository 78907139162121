/*
 * @Date: 2024-04-22 16:20:56
 * @LastEditors: R.Y
 * @LastEditTime: 2024-07-27 10:04:36
 * @FilePath: /e-app/src/App.jsx
 */
import React,{Component,useState} from "react";
import {Tabs,BackTop,Image, Breadcrumb} from "antd";
import ExchangeCenter from "./coments/ExchangeCenter/index"
import MyExchange from "./coments/MyExchange/index";
export default class App extends Component{
    
    constructor(props){
        super(props);
        this.state={
            list:[],
            activeValue:"1",
            receiverPhone:""
        }
    }

    
    componentDidMount() {
       // this.loadData();
  
      }
    loadData=()=>{
        const data = localStorage.getItem("data");
        if (data) {
            const dataObj = JSON.parse(data);
            this.setState({
                list: dataObj,
            });
            console.log("list", this.state.list);
        }
    };
    filterData=(data)=>{
        console.log("data",data);
        this.setState({
            list:data
           
        })
    };

    saveSucess=(data)=>{
        
        console.log("saveSucess",data);
        this.setState({
            list:data,
            activeValue:"2",
            receiverPhone:Number(data[0].exchangeReceiverPhone)
        })
        
        console.log("state",this.state);
    };

    handleTabChange=(value,obj)=>{
        this.setState({
            activeValue:value
        })
    };
    render(){
        const BaseUrl="/api/";
        return(
            <div style={{backgroundColor:'#fcfcfc',padding:'10px 0 0 0 '}}>
                <div style={{textAlign:'center'}}>
                    <Image src={"../img/logo.png"} style={{width:'auto',height:'80px'}} preview={false} />
                </div>
            
                
                <div style={{height:'80px',backgroundColor:'#0c00c',color:'#f0fcc',display:'flex',justifyContent:'center',alignItems:'center'}}>
                    <h2>兑换中心</h2>
                    </div>
                    
            <Tabs defaultActiveKey="1" activeKey={this.state.activeValue}  onChange={this.handleTabChange}  >
                <Tabs.TabPane tab="" key="3"></Tabs.TabPane>
                <Tabs.TabPane tab="" key="4"></Tabs.TabPane>
                <Tabs.TabPane tab="" key="5"></Tabs.TabPane>
                <Tabs.TabPane tab="兑换码兑换" key="1" >
                   <ExchangeCenter baseUrl={BaseUrl} onSaveInfo={this.saveSucess} />
                </Tabs.TabPane>
                <Tabs.TabPane tab="兑换记录查询" key="2" >
                    <MyExchange baseUrl={BaseUrl} defaultlist={this.state.list} phone={this.state.receiverPhone} onUpdate={this.filterData} />
                </Tabs.TabPane>
              
            </Tabs>
            
            <BackTop/>
            </div>
        )
    }
}