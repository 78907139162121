import React, { useState } from 'react';
import { Input, Space, Card, message, Breadcrumb, Image, Alert, Form, Button, Spin } from 'antd';
import axios from 'axios';
const ExchangeCenter = ({ baseUrl,onSaveInfo }) => {

  const { Search } = Input;
  const [exchangeNo, setExchangeNo] = useState("");
  const [exchangeError, setExchangeError] = useState("");
  const [loading, setLoading] = useState(false);
  const [projectImage, setProjecImage] = useState("");
  const [projectDesc, setProjectDesc] = useState("");
  const [projectName, setProjectName] = useState("");
  const [exchangeCodeId, setExchangeCodeId] = useState(0);
  const [exchangeSuccess, setExchangeSuccess] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [isExchanged, setIsExchanged] = useState(false);

  const [messageApi] = message.useMessage();



  const [formData, setFormData] = useState({
    id: exchangeCodeId,
    exchangeCodeNo: exchangeNo,
    exchangeReceiver: "",
    exchangeReceiverAddress: "",
    exchangeReceiverPhone: "",
    projectId:0
  });

  const dataExchangeCodeNo = {
    exchangeCodeNo:exchangeNo
  }




  const inputChange = (e) => {
    setExchangeNo(e.target.value);
    setExchangeError(false);
  };

  //通过兑换码查询项目
  const onSearch = async () => {
    setExchangeSuccess(false);
    if (!exchangeNo||exchangeNo==="") {
      setExchangeError("请输入兑换码");
      setIsSave(false);
      return;
    }
    setIsExchanged(false);
    setLoading(true);
    try {
      setExchangeNo(exchangeNo);
      const response = await axios.post(`${baseUrl}client/project`, dataExchangeCodeNo)
      if (response.data.code === 200) {
        setExchangeError();
        setIsSave(true);
        setProjectDesc(response.data.data.projectDesc);
        setProjecImage(response.data.data.projectImgUrl);
        setProjectName(response.data.data.projectName);
        setExchangeCodeId(response.data.data.exchangeCodeId);
        setFormData({...formData,projectId:response.data.data.projectId});
       

      } else {
        setExchangeError(response.data.message);
        setIsSave(false);
        setProjectDesc();
        setProjecImage();
        setProjectName();

      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };


  // 提交兑换表单
  const onFinish = async (values) => {


    setLoading(true);
    try {
      setExchangeNo(exchangeNo);
      const response = await axios.post(`${baseUrl}client/save`, formData)
      if (response.data.code === 200) {
        setIsExchanged(true);
        setIsSave(true);
        messageApi.open({
          type: 'success',
          content: '保存成功！',
        });

      
        //存到缓存
       // const localdata = localStorage.getItem("data");


        response.data.data.projectName = projectName;
        response.data.data.projectDesc = projectDesc;
        response.data.data.projectImageUrl=projectImage;
        
       /*  if (localdata) {
          const data = JSON.parse(localdata);
          data.push(response.data.data);
          localStorage.setItem("data", JSON.stringify(data));
        } else {
          const data = [];
          data.push(response.data.data);
          localStorage.setItem("data", JSON.stringify(data));
        } */
        setIsSave(false);
        /* onUpdate(response.data.data); */

   /*       setExchangeSuccess(`您的手机号码:${formData.exchangeReceiverPhone}兑换码:${formData.exchangeCodeNo},请牢记，以便后续进行查询！`);  */
   setExchangeSuccess("兑换成功!");
       messageApi.open({
        type: 'success',
        content: "兑换成功",
      });
      // onSave(response.data.data);
      } else {
        setIsExchanged(false);
        setExchangeError(response.data.message);
        setExchangeSuccess(false);
        setIsSave(false);
        messageApi.open({
          type: 'error',
          content: response.data.message,
        });
        
      }
    } catch (error) {
      messageApi.open({
        type: 'error',
        content: error,
      });
      setIsExchanged(false);
     // setExchangeSuccess();
      console.log(error);
    }
    setLoading(false);

  };


  const onFinishFailed = (errorInfo) => {
    messageApi.open({
      type: 'error',
      content: errorInfo,
    });
    console.log('Failed:', errorInfo);
  };

const onSave = (value) => {
  
  const rtnData=([]);
  rtnData.push(value);
  onSaveInfo(rtnData)
}

  return (

    <div>

      <Card style={{ textAlign: "center",paddingBottom:"0" }}>
        <Space direction="vertical" size="middle">
          <Space.Compact style={{ width: '100%' }}>

             
            <Search required={true} minLength={5} maxLength={20} placeholder="请输入兑换码，最长20个字符" onChange={inputChange} enterButton="兑换" onSearch={onSearch} style={{ width: "310px" }} />  
           
           {/* <Search minLength={5} maxLength={20} placeholder="请输入兑换码，最长20个字符" onSearch={onSave} style={{ width: "310px" }} />  */}

          </Space.Compact>
          
        </Space>
        <p style={{ textAlign: 'center',color:'#d9d9d9' }}>纪元兑换版-开启日期:可从2024年7月29日开始兑换</p>
      </Card>
      <Breadcrumb style={{ padding: 5, }} />
      {exchangeError && <Alert
        message="兑换码错误"
       
        type="error"
        showIcon
      />}

      {exchangeSuccess && <Alert
        message="兑换成功"
       
        type="success"
        showIcon
      />} 

      <Spin spinning={loading}/>
   
        {isSave && <Card>
          <div style={{ position: 'relative' }}>
            <div style={{ textAlign: 'center' }}>
              <Image
                width={315}
                src={projectImage}
              />
              <div style={{ position: 'absolute', bottom: '0em', right: '2em', transform: 'translate(0,-50%)', color: '#fff', fontSize: 'smaller' }}>{exchangeNo}</div>
            </div>
          </div>
          <p style={{ textAlign: 'center', fontSize: '1.2em' }}> {projectName}</p>
          <p style={{ textIndent: '2ch' }}>

            {projectDesc}
          </p>
        </Card>}
        <Breadcrumb style={{ padding: 5 }} />
        {isSave &&

          <Card>

            <Form
              name="basic"
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                    style={{
                        maxWidth: 400,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
            >

              <Form.Item
                label="收件人"
                name="username"
                rules={[
                  {
                    required: true,
                    message: '请输入姓名',
                  },
                ]}
              >
                <Input disabled={isExchanged} placeholder='请输入收件人姓名' onChange={(e) => { setFormData({ ...formData, exchangeReceiver: e.target.value }) }} />
              </Form.Item>
              <Form.Item
                label="联系电话"
                name="exchangeReceiverPhone"
                rules={[
                  {
                      required: true,
                      message: '手机号码不能为空'
                  },{
                      
                      pattern: /^1[3-9]\d{9}$/,
                      message: '请输入11位手机号码',
                     
                  }
              ]}
              >
                <Input disabled={isExchanged} placeholder='请输入11位手机号码' maxLength={11}  onChange={(e) => { setFormData({ ...formData, exchangeReceiverPhone: e.target.value }); }} />
              </Form.Item>
              <Form.Item
                label="收件地址"
                name="address"
                rules={[
                  {
                    required: true,
                    message: '请输入收件地址',
                  },
                ]}
              >
                <Input disabled={isExchanged} placeholder='请输入收件地址' onChange={(e) => { setFormData({ ...formData, exchangeReceiverAddress: e.target.value, exchangeCodeNo: exchangeNo, id: exchangeCodeId }) }} />
              </Form.Item>


              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <Button type="primary" htmlType="submit" loading={loading}>
                确认兑换 
                </Button>
              </Form.Item>
            </Form>
          </Card>

        }

        <Card>
          <div style={{ textIndent: "2em" }}>
          <p style={{textAlign:'center',fontWeight:'bold',fontSize:'20px'}}>兑换说明</p>
          <p>1.兑换流程:</p>
<p>1)兑换中心入口:“上海瑆藏”公众号-[我的]-[兑换中心]</p>
<p>2)在[兑换中心]输入兑换码，点击“立即兑换”</p>
<p>3)在兑换商品信息页面点击“去兑换”</p>
<p>4)输入兑换商品收货信息，点击“确认兑换”即可完成商品兑换</p>
<p></p>
<p>2.申请兑换后，将在卡面截止兑换日期前发货，限编卡编号均为随机发货，无法指定</p>
<p></p>
<p>3.请在兑换码有效期内进行兑换，过期兑换会导致兑换失败。</p>
<p></p>
<p>4.请妥善保管兑换码，因实体卡损坏、被盗或遗失而造成的损失风险由发起兑换方自行承担</p>
<p></p>
<p>5.兑换码一经使用，不接受重复兑换，请保管好兑换码信息。兑换卡地址无法修改，请确认好地址后再兑换。因地址错误、二次转寄等情况产生的商品丢失、损坏等问题，上海瑆藏不负有补寄赔偿责任和义务</p>
<p></p>
<p>6.上海瑆藏拥有法律允许范围内的最终解释权</p>
          </div>
        </Card>

    </div>
  );
};
export default ExchangeCenter; 
