import React, {useRef,useEffect, useState } from "react";
import { Card, Form, Button, List, Input, Image, message, Skeleton } from "antd";
import axios from "axios";
const MyExchange = ({ baseUrl, defaultlist, onUpdate,phone }) => {

    const [messageApi, contextHolder] = message.useMessage();
    const [formData, setFormData] = useState({
        exchangeCodeReceiverPhone: phone??""
    });
    const [inputValue, setInputValue]=useState("");

    const count = 3;
    
    const [rndColor, setRndColor] = useState("");
    const [form] = Form.useForm();
    useEffect(() => {
       
       
      if(defaultlist.length>=1){
        setInputValue(defaultlist.length>=1?defaultlist[0].exchangeReceiverPhone:"");
        form.setFieldsValue({
            exchangeCodeReceiverPhone: defaultlist[0].exchangeReceiverPhone
        });
        form.validateFields(['exchangeCodeReceiverPhone']);
        setFormData({ ...formData, exchangeCodeReceiverPhone: defaultlist[0].exchangeReceiverPhone });
      }
       
       
       
       
    }, [defaultlist, form])


const searchChange=(e)=>{
    form.setFieldsValue({
        exchangeCodeReceiverPhone:e.target.value
       });
    setInputValue(e.target.value);
   setFormData({ ...formData, exchangeCodeReceiverPhone: e.target.value });
};

    const onFinish = async (values) => {
        
        try {
            if(!Number(formData.exchangeCodeReceiverPhone)){
                messageApi.open({
                    type: 'error',
                    content: "手机号码不正确",
                }); 
             
            }else{
        /*     const localDb = defaultlist.filter(item => item.exchangeCodeNo === formData.exchangeCodeNo && item.exchangeReceiverPhone === Number(formData.exchangeCodeReceiverPhone)); */
        const localDb = ([]);
            if (localDb.length < 1) {
                const response = await axios.post(`${baseUrl}client/exchange`, formData)
                if (response.data.code === 200) {

                    onUpdate(response.data.data);

                } else {
                    messageApi.open({
                        type: 'error',
                        content: JSON.parse(response.data.message).exchangeCodeReceiverPhone[0],
                    });
                }
            } else {

                onUpdate(localDb);

            }
            }
        } catch (err) {
            messageApi.open({
                type: 'error',
                content: err,
            });
        }
    };
    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    function getRandomColor() {
        var letters = "0123456789ABCDEF";
        var color = "#";
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        var f =defaultlist;
        return color;
    }

    return (

        <div>
           {contextHolder}
            <Card style={{ textAlign: "center" }}>
                <Form
                    form={form}
                   
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                    style={{
                        maxWidth: 400,
                      
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >

                    <Form.Item
                        label="收件人电话"
                        name="exchangeCodeReceiverPhone"
                        style={{  textAlign:"left"}}
                        
                        rules={[
                            {
                                required: true,
                                message: '手机号码不能为空'
                            },{
                                
                                pattern: /^1[3-9]\d{9}$/,
                                message: '请输入11位手机号码',
                               
                            }
                        ]}
                    >
                        <div>
                        <Input type="int" value={inputValue}  maxLength={11}   placeholder='请输入11位手机号码'  onChange={searchChange} style={{width:'60%',borderRadius:'6px 0 0 6px'}} />

                        <Button type="primary" htmlType="submit" style={{width:'40%',borderRadius:'0 6px 6px 0'}}>
                            历史兑换查询
                        </Button>
                        </div>
                        <div>
                        
                        </div>
                    </Form.Item>


                </Form>
            </Card>
            <List


                itemLayout="horizontal"

                dataSource={defaultlist}
                renderItem={(item) => (
                    <List.Item>

                        <Card style={{ width: '100%' }}>
                        <div style={{textAlign:'center',fontSize:'large',padding:'inherit'}}>{item.projectName}</div>
                            <div style={{ position: 'relative' }}>
                                <div style={{ textAlign: 'center' }}>
                                    {item.projectImageUrl && <Image
                                        width={315}
                                        src={item.projectImageUrl}
                                    />

                                    }
                                    {!item.projectImageUrl &&
                                        <div style={{
                                           
                                            width: 315,
                                            height: 202,
                                            backgroundColor: 'gray',

                                            textAlign: 'center',
                                            lineHeight: '189px',
                                            color: '#fff'
                                        }} >
                                            已兑换
                                        </div>
                                    }
                                    <div style={{ position: 'absolute', bottom: '0em', right: '2em', transform: 'translate(0,-50%)', color: '#fff', fontSize: 'smaller' }}>{item.exchangeCodeNo}</div>
                                </div>
                            </div>
                           
                            <p style={{ textIndent: '2ch' }}>

                                {item.projectDesc}
                              
                            </p>
                            <div>
                                <div>兑 换 码 ：{item.exchangeCodeNo}</div>

                                <div>兑换时间：{item.exchangeTime}</div>
                                <div> 收 件 人 ：{item.exchangeReceiver}</div>
                                <div>收件地址：{item.exchangeReceiverAddress}</div>
                            </div>

                          
                        </Card>
                    </List.Item>
                )}
            />
        </div>
    );
}
export default MyExchange; 